.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}
// body {
//   background: url("../assets/bg.jpg") repeat scroll top center transparent;
// }
a.grey-color,
a.grey-color:hover {
  color: #313131;
}
